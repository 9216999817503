import styled from 'styled-components'

export const RDAccordionTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`

export const ReportDetailsChartDesc = styled.div``

export const ReportDetailsChartDescTitle = styled.p`
  @media only screen and (max-width: 550px) and (min-width: 300px) {
    white-space: normal;
    width: 85%;
    font-size: 20px;
    line-height: 24px;
    color: #2b2b2b;
    text-align: left;
    margin-bottom: 8px;
    font-weight: bold;
  }
  font-size: 20px;
  line-height: 24px;
  color: #2b2b2b;
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
`

export const ReportDetailsChartDescContent = styled.p`
   @media only screen and (max-width: 600px) and (min-width: 451px) {
   white-space: normal;
    width: 70%;
  }
    @media only screen and (max-width: 450px) and (min-width: 300px) {
    white-space: normal;
   
    }
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`

export const ReportDetailsChartCard = styled.div`
 @media only screen and (max-width: 450px) and (min-width: 300px) {
    width: 90%;
 }
`

export const ReportDetailsCard = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1445px) {
    flex-wrap: wrap;
  }
  gap: 24.5px;
`
export const ReportDetailsChartCardDivider = styled.div`
  @media only screen and (min-width: 1445px) {
    width: 4px;
    background: #e9e9e9;
    border-radius: 1.5px;
  }
`
export const GroupBarchartDesc = styled.div`
@media only screen and (max-width: 450px) and (min-width: 300px) {
  width: 90%;
}
`