import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  retainJobTimes,
  settingValues,
  CreateReportBreadcrumbData,
  EditReportBreadcrumbData,
  ReportDetailsBreadcrumbData,
  ReportDeviceDetailsBreadcrumbData
} from './consts'
import { Stack } from '@jarvis/web-stratus-client'
import React, { useEffect, useState } from 'react'
import { ReportListTableStatus } from '../components/ReportDetails/Common/ReportListTableStatus'
import { HighlightText } from '../components/HighlightText'
import { getAssessmentStatusName } from '../components/ReportDetails/Common/ReportUtils'

type ManageDeviceProps = {
  country?: string
  language?: string
  authProvider: JarvisAuthProvider
}
let localprops: ManageDeviceProps = {
  language: 'en',
  country: 'US',
  authProvider: null
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = (): ManageDeviceProps => {
  return localprops
}

export const convertDateAndTime = (date_time, locale = 'en-US') => {
  const today = date_time ? new Date(date_time) : new Date()
  return (
    `${today.toLocaleDateString(locale, {
      month: 'short'
    })} ${today
      .getDate()
      .toString()
      .padStart(2, '0')}, ${today.toLocaleDateString(locale, {
      year: 'numeric'
    })}` +
    `  ` +
    `${today.toLocaleTimeString(locale, {
      hour: '2-digit',
      hour12: true,
      minute: 'numeric'
    })}`
  ).replace(/\n/g, ' ')
}

export const refreshDate = () => {
  const today = new Date()
  const locale = 'en'
  return (
    `${today.toLocaleDateString(locale, {
      month: 'short'
    })} ${today
      .getDate()
      .toString()
      .padStart(2, '0')}, ${today.toLocaleDateString(locale, {
      year: 'numeric'
    })}` +
    `  ` +
    `${today.toLocaleTimeString(locale, {
      hour: '2-digit',
      hour12: true,
      minute: 'numeric',
      second: '2-digit'
    })}`
  ).replace(/\n/g, ' ')
}

export const getdownloadDateFormat = (date_time) => {
  const today = new Date(date_time)
  const locale = 'en'
  const dateAndTime = `${String(today.getMonth() + 1).padStart(2, '0')}${String(
    today.getDate()
  ).padStart(2, '0')}${today.getFullYear()}_${today
    .toLocaleTimeString(locale, {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit'
    })
    .replace(':', '')}`
  return dateAndTime
}

export const getDateAndTime = (date_time?, locale = 'en-US') => {
  if (date_time !== null) {
    const today = date_time ? new Date(date_time) : new Date()
    return (
      `${today.toLocaleDateString(locale, {
        month: 'short'
      })} ${today
        .getDate()
        .toString()
        .padStart(2, '0')}, ${today.toLocaleDateString(locale, {
        year: 'numeric'
      })}` +
      `  ` +
      `${today.toLocaleTimeString(locale, {
        hour: '2-digit',
        hour12: true,
        minute: 'numeric'
      })}`
    ).replace(/\n/g, ' ')
  }
  return '--'
}

//dd mmm yyyy | hh:MM:ss TT
export const getDateAndTimePDF = (date_time?, locale = 'en-US') => {
  const today = date_time ? new Date(date_time) : new Date()
  const time = today.toLocaleTimeString(locale, {
    hour: '2-digit',
    hour12: true,
    minute: 'numeric'
  })
  return `${getDateFormat(date_time, locale)} | ${time}`.replace(/\n/g, ' ')
}

//dd mmm yyyy
export const getDateFormat = (date_time, locale) => {
  if (date_time === null) {
    return '--' // or any other default value
  }
  const today = new Date(date_time)
  const day = today.getDate().toString().padStart(2, '0')
  const month = today.toLocaleDateString(locale, {
    month: 'short'
  })
  const year = today.toLocaleDateString(locale, {
    year: 'numeric'
  })
  return `${day} ${month} ${year}`.replace(/\n/g, ' ')
}

// July 17, 2023
export const getDonutDateFormat = (date_time, locale) => {
  const today = new Date(date_time)
  const day = today.getDate().toString().padStart(2, '0')
  const month = today.toLocaleDateString(locale, {
    month: 'long'
  })
  const year = today.toLocaleDateString(locale, {
    year: 'numeric'
  })
  return `${month} ${day}, ${year}`.replace(/\n/g, ' ')
}

export const timeConversion = (minValue) => {
  //the mins value will not exceed 11520 mins anytime.
  const h: any = Math.floor(minValue / 60)
  const m: any = minValue % 60

  if (minValue == 11520)
    //Just to manage the 1 min for which 8 days will be populated. It is converted to 7 days
    return '7 days'
  if (h < 24) {
    if (h == 1) return `${h}h and ${m}min`
    else return `${h}hs and ${m}min`
  } else {
    const d = Math.floor(h / 24)
    if (d == 1) return `${d} day`
    else return `${d} days`
  }
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const toUpperTitleCase = (str) => {
  let words = str.split(' ')

  words = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  return words
}

export const ReportListName = (reportType: any) => {
  const result = reportType.replace(/([A-Z])/g, ' $1')
  const final = result.charAt(0).toUpperCase() + result.slice(1)
  const lastFinal = final
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase())
  if (lastFinal === 'Executive Assessment Summary')
    return 'Executive Fleet Assessment Summary'
  else return lastFinal
}

export const NamingConvention = (data) => {
  const result = data.replace(/([A-Z])/g, ' $1')
  const final = result.charAt(0).toUpperCase() + result.slice(1)
  const lastFinal = final
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase())
  return lastFinal
}

export const getValue = (value, t, policyItem?) => {
  if (Array.isArray(value)) return ''
  switch (value) {
    case undefined:
    case '':
    case null:
    case 'NOT_SET':
    case 'not set':
      return t('ecp-global-reports.settingValues.notSet', 'Not Set')
    case 'SET':
    case 'set':
      return t('ecp-global-reports.settingValues.set', 'Set')
    case 'true':
      return t('ecp-global-reports.settingValues.enabled', 'Enabled')
    case 'false':
      return t('ecp-global-reports.settingValues.disabled', 'Disabled')
  }
  if (policyItem === 'check-latest.check') {
    return value
  }
  if (
    [
      'snmp-v3.encryption-algorithm',
      'web-encryption.tls-min',
      'web-encryption.tls-max'
    ].includes(policyItem)
  ) {
    return settingValues[value]
  }

  if (policyItem === 'web-encryption.ciphers') {
    let encryptionList = ''
    JSON.parse(value).map((item) => {
      encryptionList = encryptionList + settingValues[item] + ', '
    })
    return encryptionList
  }
  const array = value?.split('.')

  if (retainJobTimes.includes(value) || array.length === 3)
    return t(`ecp-global-reports.settingValues.${value}`, '')
  return array[array.length - 1]
}

export function getStackPrefix(stack: Stack): string {
  switch (stack) {
    case Stack.dev:
      return 'dev'
    case Stack.pie:
      return 'pie'
    case Stack.stage:
      return 'stage'
    case Stack.prod:
      return ''
  }
}

export const getFontFamily = (locale) => {
  switch (locale) {
    case 'zh-CN':
      return 'FormaDJRSCDisplay'
    case 'zh-TW':
      return 'FormaDJRTCDisplay'
    case 'ja-JP':
      return 'FormaDJRJPDisplay'
    default:
      return 'FormaDJRKoreanDisplay'
  }
}

export function getAPIURL(
  stack: Stack,
  hostPrefix: string,
  basePath: string
): string {
  const stackPrefix = getStackPrefix(stack)
  return (
    'https://' +
    (hostPrefix.length ? hostPrefix + '.' : '') +
    (stackPrefix.length ? stackPrefix + '-' : '') +
    'us1.api.ws-hp.com' +
    basePath
  )
}

export function setBreadcrumbs(breadcrumbs, navigation, t, data?) {
  const path = navigation.location.pathname.split('/')
  breadcrumbs.getBreadcrumbs().forEach((bread, i) => {
    if (i > 1) breadcrumbs.remove(bread.key)
  })
  if (path.length > 5) {
    breadcrumbs.add(ReportDeviceDetailsBreadcrumbData(data, t)[0])
    breadcrumbs.add(ReportDeviceDetailsBreadcrumbData(data, t)[1])
  } else
    switch (path[2]) {
      case 'generate-report':
        breadcrumbs.add(CreateReportBreadcrumbData(t))
        break
      case 'edit': {
        breadcrumbs.add(EditReportBreadcrumbData(t))
        break
      }
      case 'security':
      case 'jobs': {
        breadcrumbs.add(ReportDetailsBreadcrumbData(data?.name || ''))
        break
      }
      default:
        break
    }
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const callback = () => setWindowSize(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', callback)
    return () => window.removeEventListener('resize', callback)
  }, [])

  return windowSize
}

export const useDeviceView = () => {
  const resolution = useWindowSize()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const isMobileView = resolution <= 600

  return { isMobileView, isSearchOpen, setIsSearchOpen }
}

export const mapDeviceData = (devices, t, searchValue = '') => {
  return devices.map((device) => {
    let statusName, statusColor

    switch (device?.assessmentStatus?.result) {
      case 'notAssessed':
        statusName = t('ecp-global-reports.risks.notAssessed', 'Not Assessed')
        statusColor = '#7300A9'
        break
      case 'notVerified':
        statusName = t('ecp-global-reports.risks.notverified', 'Not verified')
        statusColor = '#ADADAD'
        break
      case 'good':
        statusName = t('ecp-global-reports.risks.good', 'Good')
        statusColor = '#309F2F'
        break
    }

    return {
      assessmentStatus: (
        <ReportListTableStatus
          statusName={statusName}
          statusColor={statusColor}
          searchItem={searchValue}
        />
      ),
      modelname: (
        <HighlightText
          value={device?.deviceDetails?.modelname}
          searchItem={searchValue}
        />
      ),
      serialNumber: (
        <HighlightText
          value={device?.deviceDetails?.serialNumber}
          searchItem={searchValue}
        />
      ),
      group: device?.collectionInfo ? (
        <HighlightText
          value={device?.collectionInfo[0]?.collectionName}
          searchItem={searchValue}
        />
      ) : (
        ''
      ),
      notAssessedReason: (
        <HighlightText
          value={t(
            `ecp-global-reports.deviceDetails.assessmentResultReason.${device?.assessmentStatus?.assessmentResultReason}`
          )}
          searchItem={searchValue}
        />
      )
    }
  })
}

export const deviceExportData = (devices, t, page, locale = undefined) => {
  return devices.map((device) => {
    let statusName: string = getAssessmentStatusName(
      device?.assessmentStatus?.result,
      t
    )

    let additionalFields = {}

    switch (page) {
      case 'remediation':
        additionalFields = {
          firmwareSecurity: 'Update available',
          lastRemediated:
            getDateAndTime(
              device?.assessmentStatus?.lastRemediatonTimeStamp || null
            ) || ''
        }
        break
      case 'recommendations':
        additionalFields = {
          recommendations: (
            device?.assessmentStatus?.highRiskCount +
            device?.assessmentStatus?.lowRiskCount +
            device?.assessmentStatus?.mediumRiskCount
          ).toString(),
          dateAssessed:
            getDateAndTime(
              device?.assessmentStatus?.lastAssessedTime || null
            ) || ''
        }
        break
      case 'notAssessed':
        additionalFields = {
          notAssessedReason:
            device?.assessmentStatus?.assessmentResultReason || ''
        }
        break
      case 'assessed':
        additionalFields = {
          dateAssessed:
            getDateAndTime(
              device?.assessmentStatus?.lastAssessedTime || null,
              locale
            ) || ''
        }
        break
      default:
        break
    }

    return {
      assessmentStatus: statusName || '',
      modelname: device?.deviceDetails?.modelname || '',
      serialNumber: device?.deviceDetails?.serialNumber || '',
      group: device?.collectionInfo
        ? device?.collectionInfo[0]?.collectionName
        : '',
      ...additionalFields
    }
  })
}
