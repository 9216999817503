import styled from 'styled-components'
import { typography, layout } from '@veneer/tokens/dist/tokens'
import { LogoHp } from '@veneer/core'
import { getFontFamily } from '../../utils/commonMethods'
import { Colors } from './../../utils/consts'

export const ReportDetailsChartDescTitle = styled.h1`
  font-size: 12px;
  line-height: 14px;
  // margin-top: 50px;
  color: #2b2b2b;
  text-align: center;
  margin-bottom: 8px;
  font-family: ${(props) => getFontFamily(props.locale)};
`

export const ReportDetailsChartDescContent = styled.p`
  font-size: 6px;
  line-height: 8px;
  color: #212121;
  // text-align: center;
  margin-left: 20px;
`
export const ReportPDFPagination = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 7px;
`

export const ReportPDFDisclaimer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  font-size: 8.5px;
  line-height: 10px;
`

export const ReportsRiskDot = styled.span`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${(props) => props.Color};
`

export const ReportsHPlogo = styled(LogoHp)`
  min-width: 32px;
  min-height: 32px;
  margin-right: 10px;
`

export const ReportsRiskHighlightText = styled.span`
  background-color: ${(props) => props.Color};
  color: white;
  padding: 0px 5px;
  border-radius: 2%;
  margin-left: 6px;
`

export const ReportsBanner = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  padding-bottom: 5px;
`

export const ReportsPDFContent = styled.div`
  padding: 24px;
  padding-top: 50px;
  padding-bottom: 70px;
  font-size: 12px;
  line-height: 1;
`

export const ReportsPDFGenerationTime = styled.p`
  padding: 4px 0px 2px 0px;
  color: #adadad;
`

export const ReportsSummaryPDFGenerationTime = styled.p`
  padding-top: 4px;
  padding-bottom: 4px;
  color: #BDBCBC;
`

export const ReportsBannerTitle = styled.span`
  padding-top: 5px;
  font-size: 12px;
`
export const BannerTextContainer = styled.span`
  display: flex;
  width: 480px;
`
export const BannerTextContainerText = styled.span`
  color: #656263;
`

export const ReportsBannerSubTitle = styled.span`
 color: #231F20; 
 margin-left: 3px; 
 `

export const ReportsBannerTitle2 = styled.span`
 color: #231F20; 
 margin-left: 3px; 
`

export const ReportsBannerTitle3 = styled.span`
  font-size: 8px;
  line-height: 10px;
`
export const ReportsBannerInfo = styled.div`
  margin-left: 42px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 0px;
  color: #656263;
`

export const ReportsDataNullCheck = styled.div``

export const ReportsSummaryRiskBody = styled.div`
  font-size: 6px;
  line-height: 8px;
  text-align: left;
  padding-bottom: 10px;
`

export const ReportsPDFIndicator = styled.div`
  display: flex;
  padding: 10px 8px 10px 8px;
  font-size: 10px;
  justify-content: space-between;
`

export const ReportsPDFRiskIndicatorText = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const ReportsActivitytable = styled.table`
  font-size: 10px;
  border: 0.7px solid #f1f1f1;
  margin-bottom: 3px;
  width: 100%;
`
export const ReportsActivitytableHead = styled.thead`
  background-color: rgba(58, 180, 235, 0.05);
  border-bottom: 0.7px solid #f1f1f1;
  font-size: 10px;
  padding: 5px;
`
export const ReportsActivitytableData = styled.td`
  padding: 10px 5px;
`
function getRiskColor(risk) {
  switch (risk) {
    case 'passed':
    case 'success':
      return Colors.passed
    case 'notAssessed':
      return Colors.notAssessed
    case 'lowRisk':
    case 'low':
    case 'cancelled':
      return Colors.low
    case 'mediumRisk':
    case 'medium':
      return Colors.medium
    case 'highRisk':
    case 'high':
    case 'failed':
      return Colors.high
    case 'unknown':
      return Colors.unknown
    default:
      return 'white'
  }
}
export const ReportRiskIndicator = styled(ReportsRiskDot)`
  background-color: ${(props) => getRiskColor(props.risk)};
`
export const RiskSummaryTable = styled.table`
  font-size: 8px;
  margin-bottom: 3px;
  width: 100%;
  border: 0.7px solid #f1f1f1;
`

export const RiskSummaryTableHead = styled.thead`
  background-color: rgba(33, 33, 33, 0.1);
`
export const RiskSummaryTableData = styled.td`
  padding: 5px 0px;
  &:nth-child(5) {
    width: 25%;
  }
`
export const OverallLabel = styled(RiskSummaryTableData)`
  padding-left: 6px;
`
export const OverallData = styled.td`
  white-space: nowrap;
`
export const ReportsDonutComponent = styled.div`
  padding-right: 3px;
  height: 90%;
  display: flex;
  flex: 1 1 0;
`

export const ReportsPDFDonut = styled.div`
  padding: ${layout.size3};
  flex-grow: 1;
`

export const ReportsDonutPieSymbol = styled.div`
  align-self: center;
  margin-bottom: 3px;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 1.5%;
`

export const ReportsDonutDisplayCount = styled.div`
  font-size: 6px;
  line-height: ${typography.lineHeight2};
  letter-spacing: ${layout.size0};
  text-transform: uppercase;
`

export const ReportsDonutPieChart = styled.div`
  max-height: 135px;
  max-width: 135px;
`

export const ReportsDonutPieChartComponent = styled.div`
  flex-grow: 2;
  padding-top: ${layout.size3};
`

export const ReportsDonutPieChartHeader = styled.div`
  font-size: ${typography.size0};
  text-align: center;
  padding-right: ${layout.size3};
  padding-left: ${layout.size3};
`

export const ReportsDonutPieChartSubHeader = styled.div`
  padding: 10px 10px 0px 10px;
  font-size: 6px;
  line-height: 8px;
  text-align: left;
`

export const ReportsDonutLegends = styled.div`
  margin-bottom: ${layout.size3};
`

export const ReportsDonutLegendsList = styled.div`
  flex-grow: 2;
  align-self: center;
  padding-top: ${layout.size3};
`

export const ReportsChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.type === 'executiveAssessmentSummary' ? 'column' : 'row'};
  align-self: stretch;
`

export const ReportsListItem1 = styled.p`
  font-size: 6px;
  line-height: 6px;
  margin-bottom: 3px;
`

export const ReportsListItem2 = styled.p`
  font-size: 6px;
  line-height: 6px;
  margin-bottom: 3px;
`

export const ReportsSpacing = styled.div`
  padding-bottom: ${layout.size3};
`
export const Table = styled.table`
  font-size: 10px;
  border: 0.7px solid #f1f1f1;
  margin-bottom: 7px;
  table-layout: fixed;
  width: 560px;
`

export const THead = styled.thead`
  background-color: rgba(58, 180, 235, 0.05);
  border-bottom: 0.7px solid #f1f1f1;
  font-size: 10px;
  padding: 5px;
`
export const HData = styled.div`
  padding: 10px 8px;
  white-space: nowrap;
  width: 50%;
  word-wrap: break-word;
`

export const BoldFont = styled.span``
export const PolicySettingName = styled.div`
  display: flex;
  align-items: center;
`

export const SettingName = styled.td`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  // min-width: max-content;
  padding-top: 10px;
  padding-bottom: 3px;
  word-break: break-word;
`
export const SubSettingName = styled.td`
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 3px;
  width: 30%;
`
export const ColoredDot = styled.span`
  min-height: 7px;
  min-width: 7px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${(props) => props.color};
`
export const RiskColor = styled(ColoredDot)`
  background-color: ${(props) => getRiskColor(props.risk)};
`

export const AssessmentResult = styled.td`
  padding-left: 70px;
  flex-wrap: wrap;
  padding-right: 5px;
`
export const ValueMismatchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 5px;
`

export const ReportDownloadTitle = styled.h3`
  margin-bottom: 20px;
`
export const PDFDownloadContainer = styled.div`
  max-width: 100%;
  padding: 0px 12px 0px 8px;
`

export const ReportDetails = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const ReportText = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  word-break: break-all;
  max-width: 80%;
  width: 80%;
`

export const ReportStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #212121;
  font-size: 16px;
  line-height: 20px;
  max-width: 18%;
  width: 80px;
  display: flex;
  justify-content: flex-start;
`

export const ProgressContainer = styled.div`
  padding: 5px 0;
  margin-bottom: 8px;
`
export const HorizontalDivider = styled.div`
  border-top: 1px solid #f1f1f1;
  margin: 0 10px;
`
export const ReportsLicenseSummaryContents = styled.div`
  font-size: 9px;
  line-height: 12px;
  color: #212121;
  padding: 0px 0px 8px 0px;
`
export const ReportsLicenseSummaryHeading = styled.p`
  font-size: 8px;
  line-height: 7px;
  color: #737373;
`
