import React, { useState } from 'react'
import { Table, TableColumns } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  TablePaginationText,
  Tablei18nText,
  Colors
} from '../../../../utils/consts'
import * as Wrapper from '../styles'
import { ReportListTableStatus } from '../../Common/ReportListTableStatus'
import { HighlightText } from '../../../HighlightText'
import { getDateAndTime, useDeviceView } from '../../../../utils/commonMethods'
import { ReportsFilterButton } from '../../../ReportsFilterButton'
import { SearchList } from '../../../SearchList'

const getStatusText = (status, t) => {
  const statusMapping = {
    failed: t('ecp-global-reports.statusList.failed', 'Failed'),
    cancelled: t(
      'ecp-global-reports.jobHistoryExecutiveSummary.cancelled',
      'Cancelled'
    ),
    success: t('ecp-global-reports.statusList.completed', 'Completed')
  }

  return statusMapping[status] || ''
}
const getStatusColor = (status) => {
  const colorMapping = {
    failed: Colors.high,
    cancelled: Colors.medium,
    success: Colors.passed
  }

  return colorMapping[status] || Colors.low
}

export const JobList = ({ data }) => {
  const { t } = useI18n()
  const { isMobileView, isSearchOpen, setIsSearchOpen } = useDeviceView()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(4)
  const [orderBy, setOrderBy] = useState<string>('deviceDetail')
  const [orderType, setOrderType] = React.useState<'ascending' | 'descending'>(
    'ascending'
  )
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = React.useState([
    'jobName',
    'deviceDetail',
    'date',
    'status',
    'copies'
  ])

  const createTableData = (jobs: any[], searchValue = '') => {
    if (jobs && jobs.length > 0) {
      return jobs.map((job) => {
        const status = job?.status
        const statusName = getStatusText(status, t)
        const statusColor = getStatusColor(status)

        return {
          jobName: (
            <HighlightText value={job?.jobName} searchItem={searchValue} />
          ),
          deviceDetail: (
            <HighlightText value={job?.modelName} searchItem={searchValue} />
          ),
          date: (
            <HighlightText
              value={getDateAndTime(job?.jobCompletionTime || null)}
              searchItem={searchValue}
            />
          ),
          status: (
            <ReportListTableStatus
              statusName={statusName}
              statusColor={statusColor}
              searchItem={searchValue}
            />
          ),
          copies: (
            <HighlightText value={job?.copiesCount} searchItem={searchValue} />
          )
        }
      })
    }
    return []
  }
  const [tableData, setTableData] = useState(createTableData(data.reportData))

  const handlePageSizeChange = (event, option) => {
    setPageSize(option.value)
  }
  const totalItems = tableData.length
  const handlePageChange = (page) => setCurrentPage(page)
  const finalData = tableData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )

  const getSearchResult = (searchValue) => {
    const lowerSearchValue = searchValue.toLowerCase()

    return data.reportData.filter((item) => {
      return (
        item?.jobName?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        item?.modelName?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        getStatusText(item?.status, t).toLowerCase().indexOf(lowerSearchValue) >
          -1 ||
        item?.copiesCount?.toString().toLowerCase().indexOf(lowerSearchValue) >
          -1
      )
    })
  }

  const handleSearch = (searchValue) => {
    if (searchValue) {
      let searchResult = getSearchResult(searchValue)
      setTableData(createTableData(searchResult, searchValue))
    } else {
      setTableData(createTableData(data.reportData, searchValue))
    }
  }
  function descendingComparator(a, b, orderBy) {
    const aSort = (a[orderBy] || '').toString().toUpperCase()
    const bSort = (b[orderBy] || '').toString().toUpperCase()

    return bSort.localeCompare(aSort)
  }
  function sort(array, orderBy, orderType) {
    const comparator = (a, b) => descendingComparator(a, b, orderBy)

    return orderType === 'descending'
      ? array.sort(comparator)
      : array.sort((a, b) => -comparator(a, b))
  }
  const handleSort = (_, { id = orderBy, type = orderType }: any) => {
    const sortedArray = searchText
      ? sort(getSearchResult(searchText), id, type)
      : sort(data.reportData, id, type)

    setTableData(createTableData(sortedArray, searchText))
    setOrderBy(id)
    setOrderType(type)
  }
  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
  }
  return (
    <Wrapper.ReportListTable
    isPadding={isSearchOpen}
    isResponsive={isMobileView}
    >
    <Table
      columnReorder={true}
      onColumnReorder={handleOnColumnReorder}
      actionArea={
        <Wrapper.ReportListSubHeader>
          <Wrapper.ReportListSubHeaderContainer data-testid="report_Header_Left_List">
            <SearchList
              data-testid="reports_Search_Filter_Box"
              handleSearch={handleSearch}
              setSearchText={setSearchText}
              setCurrentPage={setCurrentPage}
              searchText={searchText}
              isMobileView={isMobileView}
              setIsSearchOpen={setIsSearchOpen}
              errorDisabled={data?.reportData?.length < 1}
            />
            <ReportsFilterButton
              data-testid="reports_Filter_Button"
              errorDisabled={data?.reportData?.length < 1}
              isMobileView={isMobileView}
            />
          </Wrapper.ReportListSubHeaderContainer>
        </Wrapper.ReportListSubHeader>
      }
      data-testid="device_Assessed_Table_List_Component"
      i18n={Tablei18nText(t)}
      columns={
        [
          {
            id: 'jobName',
            label: t('ecp-global-reports.tableHeader.jobName', 'Job Name'),
            sortable: false
          },
          {
            id: 'deviceDetail',
            label: t(
              'ecp-global-reports.tableHeader.deviceDetail',
              'Device Detail'
            ),
            sortable: finalData?.length > 1 ? true : false,
            required: true
          },
          {
            id: 'date',
            label: t('ecp-global-reports.tableHeader.date', 'Date'),
            sortable: false
          },
          {
            id: 'status',
            label: t('ecp-global-reports.status', 'Status'),
            sortable: false
          },
          {
            id: 'copies',
            label: t('ecp-global-reports.tableHeader.copies', 'Copies'),
            sortable: false
          }
        ] as TableColumns[]
      }
      data={finalData}
      onSort={handleSort}
      preferences={{
        sortBy: {
          id: orderBy || 'deviceDetail',
          type: orderType || 'ascending'
        },
        width: [
          { columnId: 'jobName', width: 180 },
          { columnId: 'deviceDetail', width: 300 },
          { columnId: 'date', width: 170 },
          { columnId: 'status', width: 150 },
          { columnId: 'copies', width: 170 }
        ],
        order,
        defaultOrder: ['jobName', 'deviceDetail', 'date', 'status', 'copies']
      }}
      pagination={{
        currentPage,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        pageSize,
        pageSizeOptions: [
          { value: 2 },
          { value: 4 },
          { value: 8 },
          { value: 10 }
        ],
        i18n: TablePaginationText(t, currentPage, totalItems),
        totalItems
      }}
    />
    </Wrapper.ReportListTable>
  )
}
