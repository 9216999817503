import styled from 'styled-components'
import { typography, layout } from '@veneer/tokens/dist/tokens'

export const ReportsDonutComponent = styled.div`
  padding-right: 3px;
  display: flex;
  flex: 1 1 0;
`

export const ReportsPDFDonut = styled.div`
  padding: 28px 0px 0px 35.5px;
  flex-grow: 1;
`

export const ReportsDonutPieSymbol = styled.div`
  align-self: center;
  /* margin-bottom: 3px; */
  height: 16px;
  width: 16px;
  border-radius: 4px;
`

export const ReportsDonutDisplayCount = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-bottom: 12px;
  color: #212121;
`

export const ReportsDonutPieChart = styled.div`
  /* height: 200px;
  width: 200px; */
`

export const ReportsDonutPieChartComponent = styled.div``

export const ReportsDonutPieChartHeader = styled.div`
  font-family: ${typography.family0};
  font-size: ${typography.size0};
  text-align: center;
  padding-right: ${layout.size3};
  padding-left: ${layout.size3};
`

export const ReportsDonutPieChartSubHeader = styled.div`
  padding: 10px 10px 0px 10px;
  font-family: ${typography.family0};
  font-size: 6px;
  line-height: 8px;
  text-align: left;
`
 
export const ReportsDonutLegends = styled.div`
  /* margin-bottom: ${layout.size3}; */
  margin-top: ${(props) =>
    props.type === 'executiveAssessmentSummary' ? '28px' : '0px'};
  margin-left: ${(props) =>
    props.type === 'executiveAssessmentSummary' ? '0px' : '28px'};
`

export const ReportsDonutLegendsList = styled.div`
  /* flex-grow: 2;
  align-self: center;
  padding-top: ${layout.size3}; */
  /* padding-top: 16px; */
`

export const ReportsChart = styled.div`
  width: 100%;
  display: flex;
   @media only screen and (max-width: 500px) and (min-width: 300px) {
    flex-direction: column;
  }
  flex-direction: ${(props) =>
    props.type === 'executiveAssessmentSummary' ? 'column' : 'row'};
  align-items: ${(props) =>
    props.type === 'executiveAssessmentSummary' ? 'center' : 'stretch'};
`

export const ReportsListItem1 = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #212121;
`

export const ReportsListItem2 = styled.p`
  font-size: 12px;
  line-height: 16px;
`

export const ReportsSpacing = styled.div`
  /* padding-bottom: ${layout.size3}; */
`

export const ReportsChartList = styled.div`
  padding-bottom: 12px;
  color: #212121;
`
