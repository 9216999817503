import React from 'react'
import Donut from './Donut'
import { Colors } from '../../utils/consts'
import CategoryList from '../ReportDetails/ExecutiveFleetAssessmentSummary/DeviceAssessmentSummaryCard/CategoryList'
import {
  IconArrowDown,
  IconShieldCheckmark,
  IconFeelingSatisfied,
  IconFeelingDissatisfied,
  IconMinus,
  IconArrowUp,
  ListItem
} from '@veneer/core'
import { typography } from '@veneer/tokens/dist/tokens'
import * as Wrapper from './style'
import { getDateFormat, getDonutDateFormat } from '../../utils/commonMethods'
import HpLogo from './HpLogo'

const ExecutiveAssessmentSummaryHTML = (data, t, locale) => {
  const isData =
    data.reportData.length === 2 &&
    data.reportData[0].total !== null &&
    data.reportData[1].total !== null &&
    data.reportData[0].fleetRiskLevel !== null &&
    data.reportData[1].fleetRiskLevel !== null
  isData &&
    Object.keys(data.reportData).forEach((keyL1) => {
      if (data.reportData[keyL1] === null) data.reportData[keyL1] = '0'
      if (Object.prototype.toString.call(data.reportData[keyL1])) {
        Object.keys(data.reportData[keyL1]).forEach((keyL2) => {
          if (data.reportData[keyL1[keyL2]] === null)
            data.reportData[keyL1][keyL2] = '0'
        })
      }
    })
  const assessedDeviceSummary = (data) => {
    return [
      {
        Title: isData ? getDonutDateFormat(data.date, locale) : '--', // optional if empty string then it will not be displayed
        Subtitle: '',
        DonutDiplayText: isData
          ? (data.compliant > 0
              ? Math.floor((data.compliant / data.total) * 100)
              : 0) + '%'
          : '--',
        DonutDiplaySubText: t(
          'ecp-global-reports.executiveAssessmentSummary.innerDonutText',
          'compliant'
        ),
        TotalCount: isData ? data.total.toString() : '0',
        CategoryList: CategoryList(data, t)
      }
    ]
  }
  const resultantData = isData
    ? {
        complianceRate:
          (data.reportData[0].total > 0
            ? (
                (data.reportData[0].compliant / data.reportData[0].total) *
                100
              ).toFixed(2)
            : 0) -
          (data.reportData[1].total > 0
            ? (
                (data.reportData[1].compliant / data.reportData[1].total) *
                100
              ).toFixed(2)
            : 0),
        highRisk: (
          (data.reportData[0].total > 0
            ? (data.reportData[0].nonCompliant.highRisk /
                data.reportData[0].total) *
              100
            : 0) -
          (data.reportData[1].total > 0
            ? (data.reportData[1].nonCompliant.highRisk /
                data.reportData[1].total) *
              100
            : 0)
        ).toFixed(2),
        mediumRisk: (
          (data.reportData[0].total > 0
            ? (data.reportData[0].nonCompliant.mediumRisk /
                data.reportData[0].total) *
              100
            : 0) -
          (data.reportData[1].total > 0
            ? (data.reportData[1].nonCompliant.mediumRisk /
                data.reportData[1].total) *
              100
            : 0)
        ).toFixed(2),
        lowRisk: (
          (data.reportData[0].total > 0
            ? (data.reportData[0].nonCompliant.lowRisk /
                data.reportData[0].total) *
              100
            : 0) -
          (data.reportData[1].total > 0
            ? (data.reportData[1].nonCompliant.lowRisk /
                data.reportData[1].total) *
              100
            : 0)
        ).toFixed(2)
      }
    : null
  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />

        <Wrapper.ReportsBannerTitle>
          <Wrapper.BannerTextContainer>
            <Wrapper.BannerTextContainerText>
              <span>
                {t(
                  'ecp-global-reports.pdfHeader',
                  'Command Center Security Report:'
                )}
              </span>
            </Wrapper.BannerTextContainerText>
            <div style={{ flex: '1' }}>
              <Wrapper.ReportsBannerTitle2>
                {t(
                  'ecp-global-reports.reportsType.executiveAssessmentSummary',
                  'Executive Fleet Assessment Summary'
                )}
              </Wrapper.ReportsBannerTitle2>
            </div>
          </Wrapper.BannerTextContainer>

          <Wrapper.ReportsBannerTitle3>
            <Wrapper.ReportsPDFGenerationTime>
              {data.generatedTime}
            </Wrapper.ReportsPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 41
                  ? '20px'
                  : '0',
              color: '#656263' 
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: `}
            <span style={{ color: '#231F20' }}>{data.deviceGroup}</span>
          
          </p>
          <p>
            {t('ecp-global-reports.reportDetails.dateRange', 'Date Range')}
            {': '}
            <span style={{color: '#231F20'}}>
              {isData
                ? `${getDateFormat(
                    data.reportData[1].date,
                    locale
                  )} to ${getDateFormat(data.reportData[0].date, locale)}`
                : '--'}
            </span>
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
        <p style={{ wordBreak: 'break-word' }}>
            {`${t('ecp-global-reports.reportDetails.customer', 'Customer')}: `}
            <span style={{ color: '#231F20' }}>{data.customerName}</span>  
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsActivitytable data-testid="reports_activityTable">
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData>
              {t(
                'ecp-global-reports.executiveSummary.top',
                'Device Assessment Summary'
              )}
            </Wrapper.ReportsActivitytableData>
            <Wrapper.ReportsActivitytableData />
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <tr>
            <td
              colSpan={3}
              style={{ padding: '10px', wordBreak: 'break-word' }}
            >
              {t(
                `ecp-global-reports.executiveAssessmentSummary.assessmentCard`,
                'This data shows the percentage of printers in the fleet that comply with security policies within HP Command Center, as well as a risk summary for the given period.'
              )}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '200px'
              }}
              data-testid="reports_donutChart1"
            >
              <Donut
                data={assessedDeviceSummary(data.reportData[1])}
                type={data.reportType}
                t={t}
              />
            </td>
            <td
              style={{
                borderRight: `2px solid ${Colors.gray1}`,
                width: '200px'
              }}
            >
              <Donut
                data={assessedDeviceSummary(data.reportData[0])}
                type={data.reportType}
                t={t}
              />
            </td>
            <td>
              <ul>
                <li>
                  <ListItem
                    leadingArea={
                      isData ? (
                        resultantData?.complianceRate < 0 ? (
                          <IconArrowDown style={{ color: Colors.high }} />
                        ) : (
                          <IconArrowUp style={{ color: Colors.green6 }} />
                        )
                      ) : (
                        <IconMinus style={{ color: Colors.green6 }} />
                      )
                    }
                    centerArea={
                      <div style={{ display: 'inline-grid' }}>
                        {resultantData?.complianceRate < 0 ? (
                          <>
                            <div
                              style={{
                                fontSize: typography.size4,
                                lineHeight: typography.lineHeight5,
                                color: Colors.high
                              }}
                            >
                              {-Math.floor(resultantData?.complianceRate)}%
                            </div>
                            <p>
                              {t(
                                `ecp-global-reports.executiveAssessmentSummary.decreaseCompliance`,
                                'decrease in the compliance rate'
                              )}
                            </p>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                fontSize: typography.size4,
                                lineHeight: typography.lineHeight5,
                                color: Colors.green6
                              }}
                            >
                              {isData
                                ? `${Math.floor(
                                    resultantData?.complianceRate
                                  )}%`
                                : '--'}
                            </div>
                            <p>
                              {t(
                                `ecp-global-reports.executiveAssessmentSummary.increaseCompliance`,
                                'increase in the compliance rate'
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    }
                  />
                </li>
              </ul>
              <ListItem
                leadingArea={
                  <IconShieldCheckmark style={{ color: Colors.green6 }} />
                }
                centerArea={
                  <div style={{ display: 'inline-grid' }}>
                    <div
                      style={{
                        fontSize: typography.size4,
                        lineHeight: typography.lineHeight5,
                        color: Colors.green6
                      }}
                    >
                      {isData ? data.reportData[0].compliant : '--'}
                    </div>
                    <p>
                      {t(
                        `ecp-global-reports.executiveAssessmentSummary.deviceCompliant`,
                        'devices remains compliant with the security policy'
                      )}
                    </p>
                  </div>
                }
              />
              <Wrapper.HorizontalDivider />
              {(resultantData?.highRisk < 1 ||
                resultantData?.mediumRisk < 1 ||
                resultantData?.lowRisk < 1) && (
                <ul>
                  <li>
                    <ListItem
                      leadingArea={
                        <IconFeelingSatisfied
                          data-testid="reports-thumbsup-icon"
                          style={{ color: Colors.green6 }}
                        />
                      }
                      centerArea={
                        <div
                          style={{
                            display: 'inline-grid',
                            whiteSpace: 'wrap'
                          }}
                        >
                          {resultantData?.highRisk <= 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.highRiskFailure',
                                'High Risk'
                              )}{' '}
                              {resultantData?.highRisk != 0 ? ' (-)' : ' '}
                              {resultantData?.highRisk}%
                            </p>
                          )}
                          {resultantData?.mediumRisk <= 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.mediumRiskFailure',
                                'Medium Risk'
                              )}
                              {resultantData?.mediumRisk != 0 ? ' (-)' : ' '}
                              {resultantData?.mediumRisk}%
                            </p>
                          )}
                          {resultantData?.lowRisk <= 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.lowRiskFailure',
                                'Low Risk'
                              )}
                              {resultantData?.lowRisk != 0 ? ' (-)' : ' '}
                              {resultantData?.lowRisk}%
                            </p>
                          )}
                        </div>
                      }
                    />
                  </li>
                </ul>
              )}
              {(resultantData?.highRisk > 0 ||
                resultantData?.mediumRisk > 0 ||
                resultantData?.lowRisk > 0) && (
                <ul>
                  <li>
                    <ListItem
                      leadingArea={
                        <IconFeelingDissatisfied
                          style={{ color: Colors.high }}
                        />
                      }
                      centerArea={
                        <div
                          style={{
                            display: 'inline-grid',
                            whiteSpace: 'wrap'
                          }}
                        >
                          {resultantData?.highRisk > 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.highRiskFailure',
                                'High Risk'
                              )}{' '}
                              {' (+)'}
                              {resultantData?.highRisk}%
                            </p>
                          )}
                          {resultantData?.mediumRisk > 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.mediumRiskFailure',
                                'Medium Risk'
                              )}
                              {' (+)'}
                              {resultantData?.mediumRisk}%
                            </p>
                          )}
                          {resultantData?.lowRisk > 0 && (
                            <p>
                              {t(
                                'ecp-global-reports.risks.lowRiskFailure',
                                'Low Risk'
                              )}{' '}
                              {' (+)'}
                              {resultantData?.lowRisk}%
                            </p>
                          )}
                        </div>
                      }
                    />
                  </li>
                </ul>
              )}
            </td>
          </tr>
        </tbody>
      </Wrapper.ReportsActivitytable>
    </Wrapper.ReportsPDFContent>
  )
}

export default ExecutiveAssessmentSummaryHTML
