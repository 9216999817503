import styled from 'styled-components'

export const ReportsModalHeader = styled.p`
  width: 972px;
  height: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #212121;
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: 600px) {
    width: 100%;
    font-size: 24px;
    line-height: 28px;
  }
`

export const ReportsModalSubHeader = styled.p`
  // width: 1000px;
  // height: 48px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #404040;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px 28px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`
