import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { InputContainer, ReportNameTextBox } from './style'
import { ScopeContext } from '../../../scopes/ScopeContext'
import { getHelperText, onInput } from './ReportName_helper'
const ReportName = ({
  error,
  textError,
  reportCategory,
  reportName,
  setReportName,
  setTextError
}) => {
  const { WRITE_SCOPE } = useContext(ScopeContext)
  const { t } = useI18n()
  const [disable, setDisable] = useState(false)

  const label = t('ecp-global-reports.reportName', 'Report Name') + ' *'

  useEffect(() => {
    console.log(reportCategory)
    if (reportCategory?.reportName) {
      setDisable(!reportCategory?.reportName?.enable)
      reportCategory?.reportName?.enable || setReportName('')
    }
  }, [reportCategory])

  return disable ? (
    <></>
  ) : (
    <InputContainer error={error}>
      <p
        data-testid={`reports_Generate_ReportName_Label`}
        id={`reports_Generate_ReportName_Label`}
      >
        {label}
      </p>
      <ReportNameTextBox
        disabled={!WRITE_SCOPE}
        id="report_name_input"
        data-testid="report_name_input"
        required
        placeholder={t(
          'ecp-global-reports.generate.enterReport',
          'Enter a report name'
        )}
        onChange={(Enteredvalue: any) =>
          onInput(setReportName, setTextError, Enteredvalue)
        }
        error={error && textError}
        helperText={getHelperText(reportName, error, textError, t)}
      />
    </InputContainer>
  )
}
export default ReportName
