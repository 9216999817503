import React, { useState, useContext, useEffect } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ReportListTable } from '../ReportListTable'
import { ReportListStatus } from '../ReportListStatus'
import GeneralContext from '../../contexts/GeneralContext'
import { useReports } from '../../hooks/useReports'
import {
  convertDateAndTime,
  toTitleCase,
  ReportListName,
  setBreadcrumbs
} from '../../utils/commonMethods'
import * as Wrapper from './styles'
import { ScopeContext } from '../../scopes/ScopeContext'
import { HighlightText } from '../HighlightText'
import { Tooltip } from '@veneer/core'

export const ReportList = () => {
  const { t } = useI18n()
  const { stack, navigation, breadcrumbs, events, locale } =
    useContext(GeneralContext)
  const { READ_SCOPE } = useContext(ScopeContext)
  const { fetchReports, errorFetching } = useReports()
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [tableDataChecked, setTableDataChecked] = useState([])
  const [reportResponse, setReportResponse] = useState([])
  const [unFilteredData, setUnFilteredData] = useState<any>([])
  const [searchText, setSearchText] = useState('')
  const [disableSearchFilter, setDisableSearchFilter] = useState(false)

  const reloadReportList = (retryTimes = 2) => {
    setLoading(true)
    if (searchText) {
      setSearchText('')
    }
    fetchReports()
      .then((response) => {
        if (response?.reportList?.length > 0) {
          setTableData(
            createReportTableData(response?.reportList).sort(function (a, b) {
              var dateA = new Date(a.requestedTime).getTime()
              var dateB = new Date(b.requestedTime).getTime()
              return dateB > dateA ? 1 : -1
            })
          )
          setUnFilteredData(
            createReportTableData(response?.reportList).sort(function (a, b) {
              var dateA = new Date(a.requestedTime).getTime()
              var dateB = new Date(b.requestedTime).getTime()
              return dateB > dateA ? 1 : -1
            })
          )

          setReportResponse(response.reportList)
          setDisableSearchFilter(false)

          //START-----for calling reportList at 2 sec interval if any status is in generating state-----START//
          retryTimes < 256 &&
            response?.reportList?.map((reports) => {
              if (reports?.status?.toLowerCase() == 'inprogress') {
                setTimeout(() => {
                  reloadReportList(retryTimes * 2)
                }, 1000 * retryTimes)
              }
            })
          //END-----for calling reportList at 2 sec interval if any status is in generating state-----END//
        } else {
          setTableData([])
          setUnFilteredData([])
          setDisableSearchFilter(true)
          setReportResponse([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
        // setDateAndTime(refreshDate())
      })
  }

  useEffect(() => {
    setBreadcrumbs(breadcrumbs, navigation, t)
    if (READ_SCOPE) reloadReportList()
    if (READ_SCOPE === false) setLoading(false)
  }, [READ_SCOPE])

  const handleSearch = (searchValue) => {
    if (searchValue) {
      // let formatedData = reportResponse?.map((item) => {
      //   return {
      //     ...item,
      //     category: item?.category ? item.category : 'Security'
      //   }
      // })
      let searchResult = reportResponse.filter((item) => {
        return (
          item?.fileName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          // DO NOT DELETE : search for other columns
          // ||
          // item?.reportName.toLowerCase().indexOf(searchValue.toLowerCase()) >
          //   -1 ||
          // item?.category?.toLowerCase().indexOf(searchValue.toLowerCase()) >
          //   -1 ||
          // ReportListName(item?.reportName)
          //   ?.toLowerCase()
          //   .indexOf(searchValue.toLowerCase()) > -1 ||
          // convertDateAndTime(item?.generatedTime)
          //   ?.toLowerCase()
          //   .indexOf(searchValue.toLowerCase()) > -1 ||
          // item?.status?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        )
      })

      setTableData(
        createReportTableData(searchResult, searchValue, tableDataChecked)
      )
    } else {
      setTableData(
        createReportTableData(reportResponse, searchValue, tableDataChecked)
      )
    }
  }

  useEffect(() => {
    if (searchText === '') {
      handleSearch(searchText);
    }
  }, [searchText]);

  // useEffect(() => {
  //   handleSearch(searchText)
  // }, [searchText])

  const createReportTableData = (
    reportsData,
    searchValue = '',
    tableDataCheck = []
  ) => {
    let count = 0

    try {
      return reportsData?.map((reports) => ({
        ...reports,
        uid: count++,
        reportName:
          stack !== 'local' ? (
            <Wrapper.RedirectLink
              onClick={() => {
                reports?.status === 'ready' &&
                  navigation.push(
                    `/reports/${reports?.category || 'security'}/${
                      reports?.reportName
                    }/${reports?.reportId}`,
                    {
                      generatedTime: reports.generatedTime,
                      reportName: reports.fileName
                    }
                  )
              }}
            >
              <a>
                <HighlightText
                  value={
                    reports?.fileName || ReportListName(reports?.reportName)
                  }
                  searchItem={searchValue}
                />
              </a>
            </Wrapper.RedirectLink>
          ) : (
            <Wrapper.RedirectLink
              onClick={() => {
                navigation.push(
                  `us/en/reports/${reports?.category || 'security'}/${
                    reports?.reportType
                  }/${reports?.reportId}`,
                  {
                    generatedTime: reports.generatedTime,
                    reportName: reports.fileName,
                    groupIds: reports.groupIds
                  }
                )
              }}
            >
              <a>
                {/* <HighlightText
                  value={reports?.fileName}
                  searchItem={searchText}
                /> */}
                {reports?.fileName.length < 35 ? (
                  reports?.fileName
                ) : (
                  <Tooltip content={reports.fileName.trim(35)}>
                    {reports.fileName.trim(35)}
                  </Tooltip>
                )}
              </a>
            </Wrapper.RedirectLink>
          ),
        reportCategory: reports?.category || 'Security',
        category: reports?.category
          ? toTitleCase(
              t(
                `ecp-global-reports.generate.reportCategoryOptions.${reports?.category}`,
                reports?.category
              )
            )
          : 'Security',
        // reports?.category == undefined
        //   ? 'Not Applicable'
        //   : reports?.category
        //   ? toTitleCase(reports?.category)
        //   : '--',
        // <HighlightText
        //   value={
        //     reports?.category ? toTitleCase(reports?.category) : 'Security'
        //   }
        //   searchItem={searchText}
        // />
        name: reports?.fileName || 'Report',
        // <HighlightText
        //   value={reports?.fileName || 'Report'}
        //   searchItem={searchText}
        // />
        type: reports?.reportName,
        // <HighlightText value={reports?.reportName} searchItem={searchText} />
        time:
          reports?.generatedTime == undefined
            ? 'Not Applicable'
            : reports?.generatedTime
            ? reports?.generatedTime
            : '--',
        // <HighlightText
        //   value={reports?.generatedTime}
        //   searchItem={searchText}
        // />
        reportType:
          reports?.reportName == undefined ? (
            'Not Applicable'
          ) : reports?.reportName ? (
            t(
              `ecp-global-reports.reportsType.${reports?.reportName}`,
              ReportListName(reports?.reportName)
            ).length < 35 ? (
              t(
                `ecp-global-reports.reportsType.${reports?.reportName}`,
                ReportListName(reports?.reportName)
              )
            ) : (
              <Tooltip
                arrow
                content={
                  <>
                    {t(
                      `ecp-global-reports.reportsType.${reports?.reportName}`,
                      ReportListName(reports?.reportName)
                    )}
                  </>
                }
                id={'report-type'}
                portal={true}
              >
                <span>
                  {t(
                    `ecp-global-reports.reportsType.${reports?.reportName}`,
                    ''
                  ).substring(0, 35)}
                </span>
              </Tooltip>
            )
          ) : (
            '--'
          ),
        // <HighlightText
        //   value={ReportListName(reports?.reportName)}
        //   searchItem={searchText}
        // />
        timeStamp: reports?.generatedTime,
        generatedTime:
          reports?.generatedTime == undefined
            ? 'Not Applicable'
            : reports?.generatedTime
            ? convertDateAndTime(reports?.generatedTime, locale)
            : '--',
        // <HighlightText
        //   value={
        //     reports?.generatedTime
        //       ? convertDateAndTime(reports?.generatedTime)
        //       : 'notAvailable'
        //   }
        //   searchItem={searchText}
        // />
        status:
          reports?.status == undefined ? (
            'Not Applicable'
          ) : reports?.status ? (
            <ReportListStatus
              status={reports?.status}
              searchText={searchText}
            />
          ) : (
            <ReportListStatus status={'--'} searchText={searchText} />
          ),
        rowConfig: {
          selected:
            tableDataCheck.filter((item) => item == reports?.reportId).length >
            0
              ? true
              : false
        }
      }))
    } catch (err) {
      console.log('createReportData err', err)
    }
  }

  // useEffect(() => {
  //   setBreadcrumb(ReportListBreadcrumData)
  // }, [setBreadcrumb])
  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', reloadReportList)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', reloadReportList)
    }
  }, [events, reloadReportList])
  return (
    <Wrapper.ReportListComponent>
      <ReportListTable
        setUnFilteredData={setUnFilteredData}
        unFilteredData={unFilteredData}
        reportResponse={reportResponse}
        loading={loading}
        tableData={tableData}
        setTableData={setTableData}
        tableDataChecked={tableDataChecked}
        setTableDataChecked={setTableDataChecked}
        searchText={searchText}
        setSearchText={setSearchText}
        setLoading={(val) => setLoading(val)}
        handleSearch={handleSearch}
        reloadReportList={reloadReportList}
        handleReloadPage={() => reloadReportList()}
        errorFetching={errorFetching}
        disableSearchFilter={disableSearchFilter}
      />
    </Wrapper.ReportListComponent>
  )
}
