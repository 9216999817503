import styled from 'styled-components'

export const ReportDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
`

export const ReportDetailsTitle = styled.h6`
  word-break: break-word;
  margin-right: 10px;

`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;

  @media (max-width: 600px) {
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
`
