import React, { useContext } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import CategoryList from './CategoryList'
import {
  IconArrowDown,
  IconArrowUp,
  IconFeelingDissatisfied,
  IconFeelingSatisfied,
  IconMinus,
  IconShieldCheckmark
} from '@veneer/core'

import Donut from '../../../Charts/PieChart/Donut'
import { ReportDetailsAccordion } from '../../Common/ReportDetailsAccordion'
import { ReportDetailsHeader } from '../../Common/ReportDetailsHeader'
import { ReportDetailsList } from '../../Common/ReportsListComponent'
import { ReportDetailsAccordionContent } from '../../Common/ReportDetailsAccordionContent'

import * as Wrapper from './styles'
import GeneralContext from '../../../../contexts/GeneralContext'
import { getDonutDateFormat } from '../../../../utils/commonMethods'
import { Colors } from '../../../../utils/consts'

export const DeviceAssessmentSummaryCard = (props: { data: any }) => {
  const { data } = props
  const { t } = useI18n()
  const { locale } = useContext(GeneralContext)
  let complaintDevices = 0

  const assessedDeviceSummary = (data) => {
    return [
      {
        Legends: 'true',
        DonutDiplayText: data
          ? (data.compliant > 0
              ? Math.floor((data.compliant / data.total) * 100)
              : 0) + '%'
          : '--',
        DonutDiplaySubText: t(
          'ecp-global-reports.executiveAssessmentSummary.innerDonutText',
          'compliant'
        ),
        TotalCount: data ? data.total.toString() : '0',
        CategoryList: CategoryList(data, t)
      }
    ]
  }
  const resultantData = data
    ? {
        complianceRate:
          (parseInt(data[0].total) > 0
            ? (parseInt(data[0].compliant) / parseInt(data[0].total)) * 100
            : 0) -
          (parseInt(data[1].total) > 0
            ? (parseInt(data[1].compliant) / parseInt(data[1].total)) * 100
            : 0),
        highRisk:
          (parseInt(data[0].total) > 0
            ? (parseInt(data[0].nonCompliant.highRisk) /
                parseInt(data[0].total)) *
              100
            : 0) -
          (parseInt(data[1].total) > 0
            ? (parseInt(data[1].nonCompliant.highRisk) /
                parseInt(data[1].total)) *
              100
            : 0),
        mediumRisk:
          (parseInt(data[0].total) > 0
            ? (parseInt(data[0].nonCompliant.mediumRisk) /
                parseInt(data[0].total)) *
              100
            : 0) -
          (parseInt(data[1].total) > 0
            ? (parseInt(data[1].nonCompliant.mediumRisk) /
                parseInt(data[1].total)) *
              100
            : 0),
        lowRisk:
          (parseInt(data[0].total) > 0
            ? (parseInt(data[0].nonCompliant.lowRisk) /
                parseInt(data[0].total)) *
              100
            : 0) -
          (parseInt(data[1].total) > 0
            ? (parseInt(data[1].nonCompliant.lowRisk) /
                parseInt(data[1].total)) *
              100
            : 0)
      }
    : data

  data &&
    data.map(
      (item) => (complaintDevices = parseInt(item.compliant) + complaintDevices)
    )

  const getComplianceRisk: any = () => {
    let tempRiskCompliance = []

    if (resultantData.highRisk <= 0) {
      tempRiskCompliance.push({
        risk: resultantData.highRisk.toFixed(2).toString(),
        text: `${t('ecp-global-reports.risks.highRiskFailure', 'High Risk')} ${
          resultantData?.highRisk != 0 ? ' (-)' : ' '
        }`
      })
    }
    if (resultantData.mediumRisk <= 0) {
      tempRiskCompliance.push({
        risk: resultantData.mediumRisk.toFixed(2).toString(),
        text: `${t('ecp-global-reports.risks.mediumRiskFailure', 'Medium Risk')}
           ${resultantData?.mediumRisk != 0 ? ' (-)' : ' '}`
      })
    }
    if (resultantData.lowRisk <= 0) {
      tempRiskCompliance.push({
        risk: resultantData.lowRisk.toFixed(2).toString(),
        text: `${t('ecp-global-reports.risks.lowRiskFailure', 'Low Risk')} ${
          resultantData?.lowRisk != 0 ? ' (-)' : ' '
        }`
      })
    }
    return tempRiskCompliance
  }

  const getReportsRisk: any = () => {
    let tempRiskReports = []
    if (resultantData.highRisk > 0) {
      tempRiskReports.push({
        risk: resultantData.highRisk.toFixed(2).toString(),
        text:
          t('ecp-global-reports.risks.highRiskFailure', 'High Risk') + ' (+)'
      })
    }
    if (resultantData.mediumRisk > 0) {
      tempRiskReports.push({
        risk: resultantData.mediumRisk.toFixed(2).toString(),
        text:
          t('ecp-global-reports.risks.mediumRiskFailure', 'Medium Risk') +
          ' (+)'
      })
    }
    if (resultantData.lowRisk > 0) {
      tempRiskReports.push({
        risk: resultantData.lowRisk.toFixed(2).toString(),
        text: t('ecp-global-reports.risks.lowRiskFailure', 'Low Risk') + ' (+)'
      })
    }

    return tempRiskReports
  }

  const contentData = () => (
    <>
      <Wrapper.ReportAssessmentListSubHeader data-testid="FleetAssessmentSummary_Subheader">
        {t(
          `ecp-global-reports.executiveAssessmentSummary.assessmentCard`,
          'This data shows the percentage of printers in the fleet that comply with security policies within HP Command Center, as well as a risk summary for the given period.'
        )}
      </Wrapper.ReportAssessmentListSubHeader>
      <Wrapper.ReportDetailsCard>
        <Wrapper.ReportDetailsChartCard>
          <Wrapper.ReportDetailsDonutCard data-testid="FleetAssessmentSummary_Donut_Card">
            <div
              style={{
                width: '240px',
                left: '256px',
                marginRight: '16px'
              }}
            >
              <Wrapper.ReportDetailsChartDesc>
                <Wrapper.ReportDetailsChartDescTitle
                  data-testid="FleetAssessmentSummary_Donut_Date1"
                  id={'FleetAssessmentSummary_Donut_Date1'}
                >
                  {data ? getDonutDateFormat(data[1].date, locale) : '--'}
                </Wrapper.ReportDetailsChartDescTitle>
              </Wrapper.ReportDetailsChartDesc>
              <Donut
                data={assessedDeviceSummary(data ? data[1] : data)}
                type="executiveAssessmentSummary"
              />
            </div>
            <div
              style={{
                width: '240px',
                left: '256px'
              }}
            >
              <Wrapper.ReportDetailsChartDesc>
                <Wrapper.ReportDetailsChartDescTitle
                  data-testid="FleetAssessmentSummary_Donut_Date0"
                  id={'FleetAssessmentSummary_Donut_Date0'}
                >
                  {data ? getDonutDateFormat(data[0].date, locale) : '--'}
                </Wrapper.ReportDetailsChartDescTitle>
              </Wrapper.ReportDetailsChartDesc>
              <Donut
                data={assessedDeviceSummary(data ? data[0] : data)}
                type="executiveAssessmentSummary"
              />
            </div>
          </Wrapper.ReportDetailsDonutCard>
        </Wrapper.ReportDetailsChartCard>

        <Wrapper.ReportDetailsChartCardDivider />

        <Wrapper.ReportDetailsChartCard data-testid="FleetAssessmentSummary_List">
          <ReportDetailsList
            Icon={
              data ? (
                resultantData?.complianceRate < 0 ? (
                  <IconArrowDown
                    color={Colors.high}
                    size={48}
                    data-testid="FleetAssessmentSummary_IconArrowDown"
                  />
                ) : (
                  <IconArrowUp
                    color={Colors.green6}
                    size={48}
                    data-testid="FleetAssessmentSummary_IconArrowUp"
                  />
                )
              ) : (
                <IconMinus
                  color={Colors.green6}
                  size={48}
                  data-testid="FleetAssessmentSummary_IconMinus"
                />
              )
            }
            Rate={
              data
                ? resultantData?.complianceRate < 0
                  ? `${-Math.floor(resultantData?.complianceRate)}%`
                  : resultantData?.complianceRate > 0
                  ? `${Math.floor(resultantData?.complianceRate)}%`
                  : '0'
                : '--'
            }
            Text={
              resultantData?.complianceRate < 0
                ? t(
                    `ecp-global-reports.executiveAssessmentSummary.decreaseCompliance`,
                    'decrease in the compliance rate'
                  )
                : t(
                    `ecp-global-reports.executiveAssessmentSummary.increaseCompliance`,
                    'increase in the compliance rate'
                  )
            }
          />

          <ReportDetailsList
            Icon={<IconShieldCheckmark color={Colors.green6} size={48} />}
            Rate={data ? complaintDevices.toString() : '--'}
            Text={t(
              `ecp-global-reports.executiveAssessmentSummary.deviceCompliant`,
              'devices remains compliant with the security policy'
            )}
          />

          <Wrapper.ReportDetailsHorizontalDivider />
          {(resultantData?.highRisk <= 0 ||
            resultantData?.mediumRisk <= 0 ||
            resultantData?.lowRisk <= 0) && (
            <ReportDetailsList
              Icon={<IconFeelingSatisfied color={Colors.green6} size={48} />}
              Risk={getComplianceRisk()}
            />
          )}

          {(resultantData?.highRisk > 0 ||
            resultantData?.mediumRisk > 0 ||
            resultantData?.lowRisk > 0) && (
            <ReportDetailsList
              Icon={<IconFeelingDissatisfied color={Colors.high} size={48} />}
              Risk={getReportsRisk()}
            />
          )}
        </Wrapper.ReportDetailsChartCard>
      </Wrapper.ReportDetailsCard>
    </>
  )
  const content = () => [
    {
      content: <ReportDetailsAccordionContent ReportContent={contentData()} />,
      expanded: true,
      header: {
        centralArea: (
          <ReportDetailsHeader
            title={t(
              'ecp-global-reports.executiveSummary.top',
              'Device Assessment Summary'
            )}
            hideButton={true}
          />
        )
      },
      id: 'accordionItem2'
    }
  ]

  return <ReportDetailsAccordion content={content} />
}
