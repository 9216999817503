import { useDevices } from '../hooks/useDevices'


/**
 * Retrieves the category options for reports.
 *
 * @param t - The translation function.
 * @param config - The configuration object.
 * @param stack - The stack object.
 * @param authProvider - The authentication provider.
 * @param verifyScope - The function to verify the scope.
 * @returns A promise that resolves to an array of category options.
 */
export async function CategoryOption(
  t: (key: string, defaultValue: string) => any,
  config: any,
  stack: any,
  authProvider: any,
  verifyScope: (scope: string) => Promise<boolean>
) {
  // Function to check if the user's account type is LFdeviceAccount
  async function isLFdeviceAccount() {
        /*do not delete
      const jshellLastTenant = localStorage.getItem('jshellLastTenant')
      const tenantL1 = jshellLastTenant ? JSON.parse(jshellLastTenant)[0] : null
      const tenantId = tenantL1?.id
      const Account = localStorage.getItem(`jshellTenant-${tenantId}`)
      const account = Account ? JSON.parse(Account) : null
      const { type } = account?.data || {}
      return type?.toLowerCase() !== 'businessmpscompany'
    */
    const { fetchLFDeviceList } = useDevices(stack, authProvider)
    const res = await fetchLFDeviceList()
    return res.length > 0
  }

  const categoriesPromises = config?.reportCategories
    .filter(service => service.id !== 'deployment') // Filter out the "deployment" category
    .map(async (service) => {
      let conditionCheck
      if (service?.permissions?.deviceCache) {
        conditionCheck = await isLFdeviceAccount()
      } else {
        conditionCheck = await verifyScope(service?.permissions.scope)
      }
      return {
        value: service?.id,
        label: t(`ecp-global-reports.generate.reportCategoryOptions.${service?.id}`, service?.label),
        disabled: !(service?.enable && conditionCheck)
      }
    })
  const categories = await Promise.all(categoriesPromises || [])
  return categories
}

/**
 * Retrieves the report types based on the selected category.
 * @param t - The translation function.
 * @param config - The configuration object.
 * @param selectCategoryValue - The selected category value.
 * @returns An array of report types.
 */
export function TypeOption(
  t: (key: string, defaultValue: string) => any,
  config: any,
  selectCategoryValue: any
) {
  const category = config?.reportCategories.find(
    (service) => service.id === selectCategoryValue[0]
  )

  if (category?.reportTypes?.length > 0) {
    const types = category?.reportTypes?.map((type) => ({
      value: type.id,
      label: t(`ecp-global-reports.reportsType.${type.id}`, type.id),
      disabled: !type.enable
    }))
    return types
  }
  return []
}
