import React from 'react'
import { ListItem } from '@veneer/core'
import * as Wrapper from './styles'

export const ReportDetailsList = (props) => {
  const { Icon, Rate, Text, Risk } = props
  return (
    <ul style={{ marginBottom: '8px' }}>
      <li>
        <ListItem
          data-testid="reports_List_Item"
          leadingArea={Icon}
          centerArea={
            <div style={{ display: 'inline-grid' }}>
              {Rate ? (
                <>
                  <Wrapper.ReportAssessmentListHeader
                    data-testid="reports_Assessment_List_Header"
                    Rate={Rate}
                  >
                    {Rate}
                  </Wrapper.ReportAssessmentListHeader>
                  <Wrapper.ReportAssessmentListSubHeaderTop data-testid="reports_Assessment_List_Sub_Header">
                    {Text}
                  </Wrapper.ReportAssessmentListSubHeaderTop>
                </>
              ) : (
                <>
                  {Risk.map((data) => {
                    return (
                      <Wrapper.ReportAssessmentListSubHeader data-testid="reports_Assessment_List_Sub_Header">
                        {data.text}
                        {data.risk}%
                      </Wrapper.ReportAssessmentListSubHeader>
                    )
                  })}
                </>
              )}
            </div>
          }
        />
      </li>
    </ul>
  )
}
