import { RadioButtons } from '@veneer/core'
import styled from 'styled-components'
// import tokens from "@veneer/tokens";

export const SectionTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const ScheduleOptionRadioButtons = styled(RadioButtons)`
  margin-top: 16px;
  margin-bottom: 16px !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const SectionTitleDivider = styled.div`
  border-bottom: 1px solid #ebe7e7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
`

export const DeviceGroupTimeRadioButton = styled.div`
  margin-left: 30px;
  margin-top: 18px;

  @media (max-width: 600px) {
    margin-left: 10px;
    margin-top: 10px;
  }
`

export const SectionContainer = styled.div`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    flex-direction: column;
  }
`

export const scheduleTimeContainer = styled.div`
  margin-left: 32px;

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }
`
export const datepicker = styled.div`
 @media (max-width: 600px) {
    width: 70%;
    margin-left: 0px;
    flex-direction: column;
  }
`
export const timepicker = styled.div`
 @media (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }
`
export const scheduleTimeAndDate = styled.div`
display: flex;

 @media (max-width: 600px) {
    flex-direction: column;
  }
`
