import styled from 'styled-components'

export const RDAccordionTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`

export const ReportDetailsChartDesc = styled.div``

export const ReportDetailsChartDescTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: #2b2b2b;
  text-align: center;
`

export const ReportDetailsChartDescContent = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #212121;
`

export const ReportDetailsChartCard = styled.div``

export const ReportDetailsCard = styled.div`
@media only screen and (max-width: 1255px) and (min-width: 300px) {
    display: block;
}
  display: grid;
  grid-template-columns: 1fr 3px 1fr;
  gap: 24.5px;
`
export const ReportDetailsChartCardDivider = styled.div`
  width: 3px;
  background: #e9e9e9;
  border-radius: 1.5px;
`
export const ReportAssessmentListHeader = styled.p`
  width: 328px;
  height: 32px;
  left: 0px;
  top: 0px;

  font-size: 24px;
  line-height: 32px;
`
export const ReportAssessmentListSubHeader = styled.p`
@media only screen and (max-width: 1255px) and (min-width: 300px) {
  width: 90%;
  margin-bottom: 90px;
}
  position: static;
  width: 1000px;
  height: 20px;
  left: 24px;
  top: 24px;

  font-size: 14px;
  line-height: 20px;
  color: #212121;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 24px;
`

export const ReportDetailsHorizontalDivider = styled.div`
  border-top: 1px solid #c4c4c4;
  margin: 12px 0px;
`
export const ReportDetailsDonutCard = styled.div`
@media only screen and (max-width: 600px) and (min-width: 300px) {
    display: block;
    -webkit-box-pack: justify;
    justify-content: space-between;

}
  display: flex;
  justify-content: space-between;
`