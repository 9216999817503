import styled from 'styled-components'
// import tokens from "@veneer/tokens";

export const SectionTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const SectionTitleDivider = styled.div`
  border-bottom: 1px solid #ebe7e7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;

  @media only screen and (max-width: 600px) {
    margin: 2px 0px;
  }
`

export const EmailOptionSubContent = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const EmailOptionContent = styled.div`
  color: #7B7979;
  margin-top: 18px;
  margin-left: 32px;

  @media only screen and (max-width: 600px) {
    margin-left: 16px;
    margin-top: 12px;
  }
`

export const EmailOptionTextbox = styled.div`
  color: #7B7979;
  width: 500px;
  margin-top: 18px;
  margin-left: 32px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 16px;
  }
`

export const EmailOptionFooterContent = styled.div`
  color: #7B7979;
  width: 500px;
  margin-left: 32px;
  display: flex;

  @media only screen and (max-width: 600px) {
    display: block;
    margin-left: 16px;
    width: 100%;
  }
`

export const EmailOptionFooterContentText = styled.p`
  color: #7B7979;
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 24px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  @media only screen and (max-width: 600px) {
    white-space: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const EmailOptionFooterContentIcon = styled.div`
  margin-top: 3px;
  margin-right: 5px;

  @media only screen and (max-width: 600px) {
    margin-top: 0;
    margin-right: 0;
  }
`

export const SectionContainer = styled.div`
  margin-top: 40px;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
    width: 90%;
  }
`

export const ReportFileLanguage = styled.div`
  color: #7B7979;
  margin-top: 18px;
  margin-left: 32px;
  width: 300px;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;
