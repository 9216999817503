import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { SampleButton, InputContainer } from './style'
import { SampleModal } from '../../Modals/SampleModal'
import GeneralContext from '../../../contexts/GeneralContext'

const ReportSample = ({
  reportType,
  setloadingSampleImg,
  loadingSampleImg
}) => {
  const { stack } = useContext(GeneralContext)
  const { t } = useI18n()
  const [modalOpen, setModalOpen] = useState(false)
  const [disable, setDisable] = useState(true)

  const label = t('ecp-global-reports.generate.viewSample', 'View Sample')
  const id = `reports_Button_${label?.split(' ')?.join('')}`

  const handleButtonClick = () => setModalOpen(true)

  const loadImage = () => {
    setloadingSampleImg(true)
    setDisable(true)
    if (reportType?.sample?.fileName.length > 0) {
      setDisable(!reportType.sample.enable)
      setloadingSampleImg(false)
      return (
        <img
          id={`${reportType.sample.fileName}-sample-report`}
          data-testid={`${reportType.sample.fileName}-sample-report`}
          src={require(`../../../assets/ReportsSample/${reportType.sample.fileName}.jpg`)}
          alt="Sample Report"
          width="900px"
          height={reportType.sample.imageHeight}
        />
      )
    }
    setloadingSampleImg(false)
    return <img alt="No Sample Report" />
  }

  const img = useMemo(loadImage, [stack, reportType])

  useEffect(() => {
    loadImage()
  }, [reportType])

  return (
    <InputContainer>
      <SampleButton
        id={id}
        data-testid={id}
        appearance="secondary"
        disabled={disable}
        onClick={handleButtonClick}
      >
        {label}
      </SampleButton>
      <SampleModal
        sampleModalOpen={modalOpen}
        setSampleModalOpen={setModalOpen}
        modalSampleImage={img}
        reportID={reportType?.id}
        loadingSampleImg={loadingSampleImg}
      />
    </InputContainer>
  )
}
export default ReportSample
