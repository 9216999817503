import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  Card,
  Button,
  ProgressIndicator,
  ThemeProvider,
  useToast
} from '@veneer/core'

import { useReports } from '../../hooks/useReports'
import GeneralContext from '../../contexts/GeneralContext'
import { ScopeContext } from '../../scopes/ScopeContext'
import { CategoryOption, TypeOption } from '../../utils/CategoryAndTypesOption'

import { GenerateReportSelect } from '../GenerateReportSelect'
import ReportSample from '../GenerateReports/ReportSample'
import ReportName from '../GenerateReports/ReportName'
import DeviceGroup from '../GenerateReports/DeviceGroup'
import ReportRange from './ReportRange'
import { ScheduleOption } from '../GenerateReports/ScheduleOption'
import { EmailOption } from '../GenerateReports/EmailOption'
import { GenerateContextualFooter } from '../GenerateContextualFooter'

import * as Wrapper from './styles'
import Config from '../../config'

export const GenerateReports = () => {
  const { stack, navigation, authProvider } = useContext(GeneralContext)
  const { verifyScope } = useContext(ScopeContext)
  const { addToast } = useToast()
  const { t } = useI18n()
  const config = Config()

  const [selectCategoryValue, setSelectCategoryValue] = useState([])
  const [selectCategoryOptions, setSelectCategoryOptions] = useState([])
  const [selectTypeValue, setSelectTypeValue] = useState([])
  const [selectTypeOptions, setSelectTypeOptions] = useState([])
  const [reportCategory, setReportCategory] = useState<any>({})
  const [reportType, setReportType] = useState<any>({})
  const [reportName, setReportName] = useState('')
  const [groupIds, setGroupIds] = useState([])
  const [reportRange, setReportRange] = useState(30)

  const [generating, setGenerating] = useState(false)
  const [textError, setTextError] = useState(true)
  const [reportRangeError, setReportRangeError] = useState(false)
  const [error, setError] = useState({
    reportCategory: false,
    reportType: false,
    reportName: false,
    deviceGroup: false,
    range: false
  })
  const [loadingSampleImg, setloadingSampleImg] = useState(false)
  const [loading, setLoading] = useState(true)

  let j = 0
  const renderProcessing = (j) => {
    addToast({
      id: 'generation-processing' + j++,
      text: t(
        'ecp-global-reports.toastNotification.generationInprogressText',
        'A report is generating, please wait for notification ...'
      ),
      type: 'informative',
      timeout: 3
    })
  }
  let i = 0
  const renderError = (i) => {
    setTimeout(() => {
      addToast({
        id: 'generation-error' + i++,
        type: 'negative',
        action: (
          <Button
            small
            appearance="ghost"
            onClick={() => {
              renderProcessing(j)
              onCreateReport()
            }}
          >
            {t('ecp-global-reports.button.retry', 'Retry')}
          </Button>
        ),
        text: t('ecp-global-reports.toastNotification.generationFailuretext', {
          reportName: reportName,
          defaultValue: `Failed to generate ${reportName}.`
        }),
        timeout: 3
      })
    }, 2000) // Delay of 2 seconds
  }

  const rendersuccessful = () => {
    setTimeout(() => {
      addToast({
        id: 'Generating reports successful',
        type: 'positive',
        text: t('ecp-global-reports.toastNotification.generationSuccessText', {
          reportName: reportName,
          defaultValue: `Generating ${reportName} successfully completed.`
        }),
        timeout: 3
      })
    }, 2000) // Delay of 2 seconds
  }

  const { postReports } = useReports()
  const onCreateReport = () => {
    const data = [
      {
        collectionIds: groupIds,
        fileFormat: 'pdf',
        persist: 'false',
        category: selectCategoryValue[0],
        reportName: selectTypeValue[0],
        numDays: reportRange,
        fileName: reportName.replace(/\s+/g, ' ').trim()
      }
    ]
    if (
      selectCategoryValue.length > 0 &&
      selectTypeValue.length > 0 &&
      !textError &&
      groupIds.length > 0 &&
      !reportRangeError
    ) {
      setGenerating(true)
      setError({
        reportCategory: false,
        reportType: false,
        reportName: false,
        deviceGroup: false,
        range: false
      })
      renderProcessing(j++)
      postReports(data)
        .then(() => {
          navigation.push('/reports')
          rendersuccessful()
        })
        .catch((e) => {
          console.log('error test', e)
          renderError(i++)
        })
        .finally(() => setGenerating(false))
    } else {
      setError({
        reportCategory: selectCategoryValue.length < 1,
        reportType: selectTypeValue.length < 1,
        reportName: textError,
        deviceGroup: groupIds.length < 1,
        range: reportRangeError
      })
    }
  }

  useEffect(() => {
    setLoading(true)
    CategoryOption(t, config, stack, authProvider, verifyScope)
      .then((categories) => {
        setSelectCategoryOptions(categories)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoading(false))
  }, [])
  useEffect(() => {
    setSelectTypeValue([])
    if (!selectCategoryValue.length) {
      return
    }
    setSelectTypeOptions(TypeOption(t, config, selectCategoryValue))
  }, [selectCategoryValue])

  useEffect(() => {
    if (selectCategoryValue.length > 0) {
      const category = config.reportCategories.find(
        (service) => service.id === selectCategoryValue[0]
      )

      if (category) {
        setReportCategory(category)

        if (selectTypeValue.length > 0) {
          const reportType = category.reportTypes.find(
            (type) => type.id === selectTypeValue[0]
          )
          setReportType(reportType)
        }
      }
    }
  }, [selectTypeValue, selectCategoryValue])

  const content = () => {
    return (
      <ThemeProvider density="high">
        {loading ? (
          <ProgressIndicator style={{ padding: '3% 50%' }} />
        ) : (
          <Wrapper.GenerateReportsCardContent>
            <GenerateReportSelect
              disabled={false}
              placeholder={t(
                'ecp-global-reports.generate.reportCategoryOptions.placholderCategory',
                'Select a report category'
              )}
              label={
                t('ecp-global-reports.reportCategory', 'Report category') + ' *'
              }
              optionValue={selectCategoryValue}
              setOptionValue={setSelectCategoryValue}
              optionsList={selectCategoryOptions}
              error={error.reportCategory}
            />
            <GenerateReportSelect
              key={selectTypeOptions}
              disabled={
                selectCategoryValue.length === 0
                // ||
                // (selectCategoryValue && selectCategoryValue[0] === 'jobs')
              }
              placeholder={t(
                'ecp-global-reports.generate.reportCategoryOptions.placholder',
                'Select a report type'
              )}
              label={
                t('ecp-global-reports.reportDetails.report', 'Report Type') +
                ' *'
              }
              optionValue={selectTypeValue}
              setOptionValue={setSelectTypeValue}
              setloadingSampleImg={setloadingSampleImg}
              optionsList={selectTypeOptions}
              error={error.reportType}
            />
            <ReportSample
              reportType={reportType}
              setloadingSampleImg={setloadingSampleImg}
              loadingSampleImg={loadingSampleImg}
            />
            <ReportName
              error={error.reportName}
              textError={textError}
              reportCategory={reportCategory}
              reportName={reportName}
              setReportName={setReportName}
              setTextError={setTextError}
            />
            <DeviceGroup
              setGroupIds={setGroupIds}
              groupIds={groupIds}
              error={error.deviceGroup}
              config={config.deviceGroup}
            />

            <ReportRange
              reportType={reportType}
              reportRangeError={reportRangeError}
              setReportRangeError={setReportRangeError}
              reportrange={reportRange}
              setReportRange={setReportRange}
              error={error.range}
            />

            <ScheduleOption />

            <EmailOption />
          </Wrapper.GenerateReportsCardContent>
        )}
      </ThemeProvider>
    )
  }
  return (
    <div>
      <Card
        data-testid="reports_ScheduleOption_Card"
        content={content() as any}
        border="dropShadow"
      />
      <GenerateContextualFooter
        createReport={onCreateReport}
        generating={generating}
      />
    </div>
  )
}
