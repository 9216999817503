import styled from 'styled-components'

export const RDContainer = styled.div`
  padding: 24px;
  background-color: #f8f8f8;

`

export const ReportListTable = styled.div
`
  & > div > div {
    padding-bottom: ${(props) => (props.withPadding && props.isMobileView ? '60px' : '12px')};
  }`

export const ReportListSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-bottom: 12px;
  width: 100%;
  position: relative;
   @media (max-width: 600px) {
    margin-top: 12px;
  }
`

export const ReportListSubHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  gap: 12px;
  width:100%
`

export const RDSubContainer = styled.div`
  margin-top: 24px;
`

export const RedirectLink = styled.div`
  color: #027aae;
  cursor: pointer;
  align-items: center;
  a {
    color: inherit;
  }
`
