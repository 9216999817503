import { IconWarningAlt } from '@veneer/core'
import { typography } from '@veneer/tokens/dist/tokens'
import React from 'react'
import { getDateAndTime, getDateFormat } from '../../utils/commonMethods'
import { Tablei18nText } from '../../utils/consts'
import * as Wrapper from './style'
import HpLogo from './HpLogo'

export default function JobHistoryExecutiveSummaryHtml(data, t, locale) {
  let fromDate = new Date(data.generatedDay)
  fromDate.setDate(fromDate.getDate() - data.noOfDays)

  const statusMapping = {
    failed: t('ecp-global-reports.statusList.failed', 'Failed'),
    cancelled: t(
      'ecp-global-reports.jobHistoryExecutiveSummary.cancelled',
      'Cancelled'
    ),
    success: t('ecp-global-reports.statusList.completed', 'Completed')
  }

  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />
        <Wrapper.ReportsBannerTitle>
          <Wrapper.BannerTextContainer>
            <Wrapper.BannerTextContainerText>
              <span>
                {t(
                  'ecp-global-reports.pdfHeader',
                  'Command Center Security Report:'
                )}
              </span>
            </Wrapper.BannerTextContainerText>
            <div style={{ flex: '1' }}>
              <Wrapper.ReportsBannerTitle2>
                {t('ecp-global-reports.reportsType.jobSummary', 'Job Summary')}
              </Wrapper.ReportsBannerTitle2>
            </div>
          </Wrapper.BannerTextContainer>

          <Wrapper.ReportsBannerTitle3>
            <Wrapper.ReportsPDFGenerationTime>
              {data.generatedTime}
            </Wrapper.ReportsPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 41
                  ? '20px'
                  : '0',
           color: '#656263'
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: `}
            <span style={{ color: '#231F20' }}>{data.deviceGroup}</span>
          </p>
          <p>
            {t('ecp-global-reports.reportDetails.dateRange', 'Date Range')}
            {': '}
            <span style={{ fontFamily: typography.family0, color: '#231F20' }}>
              {`${getDateFormat(fromDate, locale)} to  ${getDateFormat(
                data.generatedDay,
                locale
              )} `}
            </span>
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsActivitytable>
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData style={{ paddingLeft: '15px' }}>
              {t('ecp-global-reports.reportsType.jobSummary', 'Job Summary')}
            </Wrapper.ReportsActivitytableData>
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          <div style={{ margin: '15px' }}>
            {t(
              'ecp-global-reports.jobHistoryExecutiveSummary.jobsSummaryInfo',
              'Total completed jobs'
            )}
            <p
              style={{ fontSize: '15px', color: '#212121' }}
              data-testid="Job_Summary_Completed_Job_Count"
            >
              {data?.reportData?.statusCount?.success || 0}
            </p>
          </div>
        </tbody>
      </Wrapper.ReportsActivitytable>
      <Wrapper.ReportsActivitytable>
        {' '}
        <Wrapper.ReportsActivitytableHead>
          <tr>
            <Wrapper.ReportsActivitytableData style={{ width: '25%' }}>
              {t('ecp-global-reports.tableHeader.jobName', 'Job Name')}
            </Wrapper.ReportsActivitytableData>

            <Wrapper.ReportsActivitytableData style={{ width: '25%' }}>
              {t(
                'ecp-global-reports.tableHeader.deviceDetail',
                'Device Detail'
              )}
            </Wrapper.ReportsActivitytableData>

            <Wrapper.ReportsActivitytableData style={{ width: '20%' }}>
              {t('ecp-global-reports.tableHeader.date', 'Date')}
            </Wrapper.ReportsActivitytableData>

            <Wrapper.ReportsActivitytableData>
              {t('ecp-global-reports.status', 'Status')}
            </Wrapper.ReportsActivitytableData>

            <Wrapper.ReportsActivitytableData style={{ width: '6%' }}>
              {t('ecp-global-reports.tableHeader.copies', 'Copies')}
            </Wrapper.ReportsActivitytableData>
          </tr>
        </Wrapper.ReportsActivitytableHead>
        <tbody>
          {data?.reportData?.jobs?.length > 0 ? (
            data.reportData.jobs.map((job) => {
              return (
                <tr key={job?.jobCompletionTime}>
                  <Wrapper.ReportsActivitytableData style={{ width: '25%' }}>
                    {job?.jobName}
                  </Wrapper.ReportsActivitytableData>
                  <Wrapper.ReportsActivitytableData style={{ width: '25%' }}>
                    {job?.modelName}
                  </Wrapper.ReportsActivitytableData>
                  <Wrapper.ReportsActivitytableData style={{ width: '20%' }}>
                    {getDateAndTime(job?.jobCompletionTime || null, locale)}
                  </Wrapper.ReportsActivitytableData>
                  <Wrapper.ReportsActivitytableData
                    style={{
                      display: 'flex',
                      paddingBottom: '1px'
                    }}
                  >
                    <Wrapper.ReportRiskIndicator risk={job?.status} />
                    <> {statusMapping[job?.status] || ''}</>
                  </Wrapper.ReportsActivitytableData>
                  <Wrapper.ReportsActivitytableData style={{ width: '6%' }}>
                    {job?.copiesCount}
                  </Wrapper.ReportsActivitytableData>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    margin: '5% 200px'
                  }}
                >
                  <span style={{ alignSelf: 'center' }}>
                    <IconWarningAlt />
                  </span>

                  <p
                    style={{
                      alignSelf: 'center',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {Tablei18nText(t).noItems}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Wrapper.ReportsActivitytable>
    </Wrapper.ReportsPDFContent>
  )
}
